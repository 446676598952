
<template>
    <ul v-if="!loading" class="role-table">
        <li class="header">
            <div class="left">功能模块</div>
            <div class="right">权限列表</div>
        </li>
        <div class="vertical-line"></div>
        <li v-for="(moduls,appname) in routers" :key="appname">
            <div class="left h40"  @click="fold(appname)">
                <i :class="{'el-icon-caret-right':routerfold[appname],'el-icon-caret-bottom':!routerfold[appname]}" class="item-icon" v-cloak ></i>
                <span v-cloak>{{appname}}</span>
            </div>

            <div class="right h40">
                <el-checkbox :checked="appchecked(moduls)" @change="(checked)=>onAppAllChange(checked,moduls)">所有</el-checkbox>
            </div>
            <div class="linew"></div>
            <ul v-show="!routerfold[appname]">
                <template v-for="(modul,modulname) in moduls">
                    <li  v-if="modul.routes.length > 0" :key="modulname">
                        <div class="left">
                            <el-checkbox :checked="modulcheck(modul.routes)" @change="(checked)=>onModAllChange(checked,modul.routes)" v-cloak>
                                {{modul.title}}
                            </el-checkbox>
                        </div>
                        <div class="right">
                            <el-checkbox v-for="(route,index) in modul.routes" :label="route.method+'@'+route.router" :key="index" :checked="binds.indexOf(route.method+'@'+route.router) > -1" @change="onBindsChange" v-cloak >
                                {{route.title}}
                            </el-checkbox>
                        </div>
                        <div class="linew"></div>
                    </li>
                </template>
                
            </ul>
        </li>
    </ul>
</template>

<script>
export default {
    name: "page-router",
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        value: {type: String,default:''},
        split: {type: String,default: ','}
    },
    data() {
        return {
            binds:[],
            routers:$router,
            routerfold:{},
            loading:false,
        };
    },
    created() {
        console.log($router)
        if( this.value ){
            this.binds = this.value.split(this.split) || [];
        }
    },
    methods: {
        fold: function (appname) {
            if (typeof this.routerfold[appname] === "undefined") {
                this.$set(this.routerfold, appname, true);
            } else {
                this.$set(this.routerfold,appname, !this.routerfold[appname]);
            }
        },
        appchecked(moduls){
            for(let modulname in moduls){
                for(let key in moduls[modulname].routes){
                    if( this.binds.indexOf(moduls[modulname].routes[key].method+'@'+moduls[modulname].routes[key].router) == -1 ){
                        return false;
                    }
                }
            }
            return true;
        },

        modulcheck(routes){
            for(let key in routes){
                if( this.binds.indexOf(routes[key].method+'@'+routes[key].router) == -1 ){
                    return false;
                }
            }
            return true;
        },

        onAppAllChange(checked,moduls){
            this.loading = true;
            if( checked ){
                for(let modulname in moduls){
                    for(let key in moduls[modulname].routes){
                        let router  = moduls[modulname].routes[key].method+'@'+moduls[modulname].routes[key].router;
                        let indexof = this.binds.indexOf(router);
                        if( indexof == -1 )this.binds.push(router)
                    }
                }
            }else{
                for(let modulname in moduls){
                    for(let key in moduls[modulname].routes){
                        let router  = moduls[modulname].routes[key].method+'@'+moduls[modulname].routes[key].router;
                        let indexof = this.binds.indexOf(router);
                        if( indexof > -1 )this.binds.splice(indexof,1);
                    }
                }
            }
            this.$nextTick(()=>{
                this.loading = false;
            })
        },
        onModAllChange(checked,routes){
            this.loading = true;
            if( checked ){
                for(let key in routes){
                    let router  = routes[key].method+'@'+routes[key].router;
                    let indexof = this.binds.indexOf(router);
                    if( indexof == -1 )this.binds.push(router)
                }
            }else{
                for(let key in routes){
                    let router  = routes[key].method+'@'+routes[key].router;
                    let indexof = this.binds.indexOf(router);
                    if( indexof > -1 )this.binds.splice(indexof,1);
                }
            }
            this.$nextTick(()=>{
                this.loading = false;
            })
        },
        onBindsChange(checked,node){
            let indexof = this.binds.indexOf(node.target.defaultValue);
            if( checked ){
                if( indexof==-1 ){
                    this.loading = true;
                    this.binds.push(node.target.defaultValue);
                    this.$nextTick(()=>{
                        this.loading = false;
                    })
                }
            }else if(indexof > -1){
                this.loading = true;
                this.binds.splice(indexof,1);
                this.$nextTick(()=>{
                    this.loading = false;
                })
            }
        }
    },
    watch: {
        value(value) {
            this.binds = value?value.split(this.split):[];
        },
        binds(vals) {
            this.$emit('change', typeof(vals)=='string'?vals:vals.join(this.split));
        }
    }
};
</script>
<style lang="scss" scoped>
.role-table {
    border: 1px solid #e0e0e0;
    border-bottom: none;
    padding: 0;
    position: relative;
    overflow: auto;
}

.header {
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #e7e7e7;
    background: #F8F8F9;
    text-align: center;
}

.vertical-line {
    width: 1px;
    height: 100%;
    background: #ddd;
    position: absolute;
    left: 30%;
    top: 0
}

.left {
    width: 30%;
    float: left;
    padding-left: 10px;
    user-select: none;
    cursor: pointer;
}

.one {
    padding-left: 20px;
}

.right {
    width: 70%;
    float: left;
    padding-left: 10px;
}

.item-icon {
    margin-left: -5px;
    padding: 5px;
}

.linew {
    clear: both;
    width: 100%;
    height: 1px;
    background: #e0e0e0;
}
.h40{
    height: 39px;
    line-height: 39px;
}
[v-cloak] {
    display: none;
}
</style>